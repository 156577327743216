import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import HeaderPress from "../components/HeaderPress";
import language_config from "../../language-config";

import comunicati from "../images/comunicati.svg";
import mediaGallery from "../images/media-gallery.svg";

import "../utils/general.scss";
import "../utils/page-press.scss";

const Press = ({ pageContext }) => {
    let [path, setPath] = useState('/');

    useEffect(() => {
        let newPath = ''
        if (typeof window !== 'undefined') {
            newPath = ((language_config[window.localStorage.language].is_default ? '' : ('/' + window.localStorage.language)) + '/')
        } else {
            newPath = '/'
        }
        setPath(newPath)
    }, []);

    return (
        <Layout pageContext={pageContext}>
            <SEO title={pageContext.localeResources['menu.press']}>
                {typeof window !== 'undefined' &&
                    <link href={window.location} rel="canonical" />
                }
            </SEO>

            <HeaderPress title={pageContext.localeResources['press.main.title']} />

            <section className="section-press colorful-bg">
                <div className="container section">
                    <Link className="card card-split-page rounded ie" to={`${path}press-comunicati`} data-aos="fade-right" data-aos-offset="200">
                        <img alt="" src={comunicati} style={{width: "80px"}} />
                        <h5 className="subtitle gray">{pageContext.localeResources['press.comunicati.title']}</h5>
                    </Link>
                    <Link className="card card-split-page rounded ie" to={`${path}press-media`} data-aos="fade-left" data-aos-offset="200">
                        <img alt="" src={mediaGallery} style={{width: "90px"}} />
                        <h5 className="subtitle gray">{pageContext.localeResources['press.media.title']}</h5>
                    </Link>
                </div>
            </section>
        </Layout>
    )
}

export default Press
